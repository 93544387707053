import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { showModal } from "@/core/helpers/dom";
import { Actions } from "@/store/enums/StoreEnums";

export default function useLanguage() {

  const store = useStore();

  const componentLoading = ref(true)
  const languageOptions = ref(null) as any;
  const modalRef1 = ref<null | HTMLElement>(null);

  const fieldName = ref();
  const fieldValue = ref();
  const fieldType = ref();
  const fieldCurrentData = ref();
  
  const renderAmount = ref(0)

  const userLanguage = computed(() => {
    return store.getters.currentUser.language;
  });
  
  const profileLanguages = computed(() => {
    return store.getters.allProfiles.items;
  });

  const languageModal = (previousModalId) => {
    if (previousModalId != undefined) {
      const previousModal = document.getElementById(previousModalId)
      const previousInstance = Modal.getInstance(previousModal)
      previousInstance.hide();
    }

    const currentModal = document.getElementById(previousModalId + '_lang')
    const currentInstance = Modal.getOrCreateInstance(currentModal)
    currentInstance.show();
  }

  const initLanguageModal = (data) => {
    console.log(data)
    fieldName.value = data.fieldName
    fieldValue.value = data.fieldValue
    fieldType.value = data.fieldType
    fieldCurrentData.value = data.currentData
    renderAmount.value += 1
  }

  const getAvailableLanguages = async (data) => {
    if (data === undefined) {
      return;
    }
    // Get all possible languages based on profiles
    const arr = [] as any;
    const obj = {};

    let i;

    await store.dispatch(Actions.PROFILES).then(async () => { 

    const object = profileLanguages.value;
    for (i = 0; i < object.length; i++) {
      // Only create new entries on object when language is not null
      if (object[i].language) {
        arr.push(object[i].language)
        obj[object[i].language] = '';
      }
    }

    if (data !== null && data !== '') {
      for (const [key, value] of Object.entries(data)) {
        obj[key] = value
      }
    }

    languageOptions.value = obj
    componentLoading.value = false;
    })
  }
  
  const createLanguageOptions = async (data) => {
    const obj = {}
    obj[userLanguage.value] = JSON.parse(data);
    return obj;
  }

  const convertToText = (data: string, method?: string) => {
    console.log(data)
    let convertedText
    let obj;

    if (data) {
      let lang = true;
      try {  
        obj = JSON.parse(data)
      } catch (e) {  
        obj = data
        lang = false
      }

      console.log(lang)
      if (method == 'profile') {
        const language = store.getters.currentSale.language

        if (language in obj) {
          convertedText = obj[language]
        } else {
          convertedText = Object.values(obj)[0]
        }
      } else {
        if (typeof obj === 'object' && obj !== null) {
          if (userLanguage.value in obj) {
            convertedText = obj[userLanguage.value]
          } else {
            convertedText = Object.values(obj)[0]
          }
        } else {
          convertedText = obj
        }
      }
    }
    return convertedText;
  }

  return {
    createLanguageOptions,
    convertToText,
    getAvailableLanguages,
    languageOptions,
    languageModal,
    modalRef1,
    fieldName,
    fieldValue,
    fieldType,
    renderAmount,
    initLanguageModal,
    componentLoading,
    fieldCurrentData
  }
}